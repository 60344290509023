.center-main {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
}

.center-box {
    display: table-cell;
    padding-top: 35px;
    padding-bottom: 35px;
    vertical-align: middle;
}
.logo {
    
}